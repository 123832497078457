import { useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Auth } from "aws-amplify";
import { JoiningDetailResType } from "../lib/JoiningDetailType";
import { EventResType } from "../lib/EventType"
import { useLocation , useNavigate, useSearchParams} from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import Card from '@mui/material/Card';
import { CardContent, Typography , CircularProgress} from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import { toDisplayDate, calcDuration, getJpEventTitle } from "../lib/lib";
import { EventIcon } from "../components/eventDetail/EventIcon";
import { EventBody } from "../components/eventDetail/EventDetailTImelineBody";

const fetchDetail= async (joining_uuid: string) => {
    const idToken = await Auth.currentSession().then(res =>{
      return res.getIdToken().getJwtToken();
    })
    const res = await axios.post('https://api.vrclc.hennabatch.net/joining_uuid/details', {
        'joining_uuid': joining_uuid
      }, {
      headers:{
        "Authorization" : idToken
      }
    });
    return res.data as JoiningDetailResType;
}

type JoiningDetailInfoType = {
    joiningDetailInfo: JoiningDetailResType | undefined
}

const JoiningDetailInfo = ({joiningDetailInfo}: JoiningDetailInfoType) =>{
    if(joiningDetailInfo === undefined){
        return(
            <Card >
                <CircularProgress/>
            </Card>
        )
    }

    return (
        <Card>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {toDisplayDate(joiningDetailInfo!.date_from)} ～ {toDisplayDate(joiningDetailInfo!.date_to)}<br />
            {calcDuration(joiningDetailInfo!.date_from, joiningDetailInfo!.date_to)}
            </Typography>
            <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                {joiningDetailInfo?.world}
            </Typography>
            <Typography variant="body2">
                出会ったプレイヤー
            </Typography>
            <ul>
                {joiningDetailInfo!.unique_players.map((result) => {
                    return <li key={result}>{result}</li>
                })}
            </ul>
            <Typography variant="body2">
                撮った写真: {joiningDetailInfo!.picture_count}枚<br />
                launchingUUID:{joiningDetailInfo.launching_uuid}<br />
                joiningUUID:{joiningDetailInfo.joining_uuid}
            </Typography>
        </CardContent>
        </Card>
    )
}

type EventInfoType = {
    eventInfo: EventResType | undefined
}

const EventInfo = ({eventInfo}: EventInfoType) => {
    if(eventInfo === undefined){
        return(
            <VerticalTimelineElement
            className="vertical-timeline-element-joining-detail"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<PlaceIcon />}
        >
            <CircularProgress/>
        </VerticalTimelineElement>
        )
      }

    return (
        <VerticalTimelineElement
            className="vertical-timeline-element-joining"
            date={`${toDisplayDate(eventInfo.occurred_at)}`}
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<EventIcon eventInfo={eventInfo!}/>}
        >
            <h3 className="vertical-timeline-element-title">{getJpEventTitle(eventInfo)}</h3>
            <EventBody eventInfo={eventInfo!}/>
        </VerticalTimelineElement>
    )
}

type joiningUUIDType = {
    joiningUUID: string | undefined;
    movedPath: string | undefined;
    refState: any | undefined;
}

export default function TimeLineDetail() {
    const [searchParams, setSearchParams] = useSearchParams();
    const joiningUUID = searchParams.get("joiningUUID") != undefined ? searchParams.get("joiningUUID") : ''

    const {data: joiningDetailInfo} = useQuery<JoiningDetailResType>(['joiningDetail', joiningUUID], () => fetchDetail(joiningUUID!), {enabled: !!joiningUUID});

    if(joiningUUID === undefined){
        return (
            <main>
            <h1>タイムライン詳細</h1>
            joiningUUIDが見つかりませんでした。
        </main>
        )
    }

    return (
        <main>
            <h1>タイムライン詳細</h1>
            <JoiningDetailInfo joiningDetailInfo={joiningDetailInfo}/>
            <VerticalTimeline
            layout="1-column-left"
            >
                {
                   (joiningDetailInfo?.events ? joiningDetailInfo.events : Array<EventResType>())
                   .map((result) => {
                        return (<EventInfo eventInfo={result} />)
                    })
                }
            </VerticalTimeline>
        </main>
    )
}