import Typography from "@mui/material/Typography"
import { EventResType } from "../../lib/EventType"
import { useState } from "react"
import React from "react"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { VideoOverview } from "../../lib/videoLib";

export type EventIconType ={
    eventInfo: EventResType
}

export const EventBody= ({eventInfo}: EventIconType) =>{
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    switch(eventInfo ? eventInfo.event_name : ""){
        case 'JoinWorldEvent':
            return (
                <>
                <Typography variant="body2">
                    <p>{eventInfo.event_name}</p>
                </Typography>
                <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                    ID: {eventInfo.event_id}
                </Typography>
                </>
            )
        case 'LeaveWorldEvent':
            return (
                <>
                <Typography variant="body2">
                    <p>{eventInfo.event_name}</p>
                </Typography>
                <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                    ID: {eventInfo.event_id}
                </Typography>
                </>
            )
        case 'ComePlayerEvent':
            return (
                <>
                <Typography variant="body2">
                    <p>{eventInfo.event_name}</p>
                </Typography>
                <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                    ID: {eventInfo.event_id}
                </Typography>
                </>
            )
        case 'LeftPlayerEvent':
            return (
                <>
                <Typography variant="body2">
                    <p>{eventInfo.event_name}</p>
                </Typography>
                <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                    ID: {eventInfo.event_id}
                </Typography>
                </>
            )
        case 'ChangeAvatarEvent':
            return (
                <>
                <Typography variant="body2">
                    <p>{eventInfo.event_name}</p>
                    <details>
                        <summary>詳細</summary>
                        <p>アバターID: <a target="_blank" href={`https://vrchat.com/home/avatar/${eventInfo.avatar_id}`}>{eventInfo.avatar_id}</a></p>
                    </details>
                </Typography>
                <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                    ID: {eventInfo.event_id}
                </Typography>
                </>
            )
        case 'LoadVideoEvent':
            return (
                <>
                <Typography variant="body2">
                    <p>{eventInfo.event_name}</p>
                </Typography>
                <VideoOverview url={eventInfo.video_url!}/>
                </>
            )
        case 'TakePictureEvent':
            const style = {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            };
            return (
                <>
                <Typography variant="body2">
                    <p>{eventInfo.event_name}</p>
                    <img 
                        loading="lazy" 
                        width="50%" 
                        height="50%" 
                        src={eventInfo.picture!.urls.thumbnail!}
                        onClick={handleOpen}
                    />
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                        <img 
                            loading="lazy" 
                            width="80%" 
                            height="80%" 
                            src={eventInfo.picture!.urls.thumbnail!}
                            onClick={handleOpen}
                        />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {eventInfo.picture?.name}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {eventInfo.picture ? (
                                eventInfo.picture!.urls.thumbnail ? <><a target="_blank" href={eventInfo.picture!.urls.thumbnail}>SD</a><br/></> : null
                            ): null}
                            {eventInfo.picture ? (
                                eventInfo.picture!.urls.fullhd ? <><a target="_blank" href={eventInfo.picture!.urls.fullhd}>FHD</a><br/></> : null
                            ): null}
                            {eventInfo.picture ? (
                                eventInfo.picture!.urls.original ? <><a target="_blank" href={eventInfo.picture!.urls.original}>Original</a><br/></> : null
                            ): null}
                        </Typography>
                        </Box>
                    </Modal>

                    <details>
                        <summary>詳細</summary>
                        <p>ワールド: {eventInfo.world}</p>
                        <p>プレイヤー: </p>
                        {eventInfo.players?.map(x => <>{x}<br/></>)}
                    </details>
                </Typography>
                <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                    ID: {eventInfo.event_id}
                </Typography>
                </>
            )
        case 'AcceptFriendRequestEvent':
            return (
                <>
                <Typography variant="body2">
                    {eventInfo.event_name}
                </Typography>
                <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                    ID: {eventInfo.event_id}
                </Typography>
                </>
            )
        case 'AcceptInviteEvent':
            return (
                <>
                <Typography variant="body2">
                    {eventInfo.event_name}
                </Typography>
                <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                    ID: {eventInfo.event_id}
                </Typography>
                </>
            )
        case 'AcceptRequestInviteEvent':
            return (
                <>
                <Typography variant="body2">
                    {eventInfo.event_name}
                </Typography>
                <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                    ID: {eventInfo.event_id}
                </Typography>
                </>
            )
        default: 
        return (
            <>
            <Typography variant="body2">
                {eventInfo.event_name}
            </Typography>
            <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                ID: {eventInfo.event_id}
            </Typography>
            </>
        )
    }
}