
function isYoutubeVideo(url: string): boolean{
    return url.startsWith('https://youtu.be') || url.startsWith('https://www.youtube.com') || url.startsWith('https://youtube.com')
}

function extractVideoIdForYoutube(url: string): string | null{
    if(url.startsWith('https://youtu.be')){
        const splits = url.split('/')
        return splits[splits.length - 1]
    }else if(url.startsWith('https://www.youtube.com') || url.startsWith('https://youtube.com')){
        let vid = url.split('=')[1]
        if(vid.indexOf('&') != -1){
            vid = vid.split('&')[0]
        }
        return vid
    }
    return null
}

function isNicoNicoVideo(url: string): boolean{
    const regex = new RegExp("^https://vodedge[0-9]+\.dmc\.nico")
    return url.startsWith('https://www.nicovideo.jp') ||  url.match(regex) != null
}

function extractVideoIdForNicoNico(url: string): string | null{
    const regex = new RegExp("^https://vodedge[0-9]+\.dmc\.nico")
    if(url.startsWith('https://www.nicovideo.jp')){
        let vid = url
        if(url.indexOf('?') != -1){
            vid = url.split('?')[0]
        }
        const splits = vid.split('/')
        return splits[splits.length - 1]
    }else if(url.match(regex) != null){
        return url.split('-')[1].split('_')[0]
    }
    return null
}

export type VideoInfo = {
    url: string
}

export const VideoOverview = ({url}: VideoInfo) => {
    if(isYoutubeVideo(url)){
        //youtubeの動画
        const vid = extractVideoIdForYoutube(url)
        return (
            <div>
                <iframe 
                    title={url}
                    src={`https://www.youtube.com/embed/${vid}`}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                >
                </iframe>
            </div>
        )
    }else if(isNicoNicoVideo(url)){
        const vid = extractVideoIdForNicoNico(url)
        return (
            <div>
                <iframe 
                    title={url}
                    src={`https://embed.nicovideo.jp/watch/${vid}`}
                >
                </iframe>
            </div>
        )
    }
    return (
        <p>動画URL: <a target="_blank" href={url}>{url}</a></p>
    )
}

<iframe width="312" height="176" src="https://ext.nicovideo.jp/thumb/sm33977403">
    <a href="https://www.nicovideo.jp/watch/sm33977403">【Undertale】MEGALOVANIA (Abandon Arrange)</a>
</iframe>