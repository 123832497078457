import { EventResType } from "./EventType";

export function toDisplayDate(unixTime: string){
    return new Date(parseInt(unixTime) * 1000).toLocaleString();
}

export function toDisplayBeforeDays(unixTime: string){
    const targetDate = new Date(parseInt(unixTime) * 1000);
    const current = new Date();

    //差が1週間以内であれば日付+'前'で返す
    const diffAllDays = Math.floor((current.getTime() - targetDate.getTime()) / 86400000)
    if(diffAllDays === 0){
        return '今日'
    }else if(diffAllDays === 1){
        return '昨日'
    }else if(diffAllDays < 7){
        return diffAllDays+'日前'
    }

    //差が1週間移行であれば日付以降を返す
    const diffYears = current.getFullYear()- targetDate.getFullYear();
    if(diffYears === 0){
        return (targetDate.getMonth() + 1) + '/' + (targetDate.getDate());
    }
    return targetDate.getFullYear() + '/' +(targetDate.getMonth() + 1) + '/' + (targetDate.getDate() + 1);
    
}

export function calcDuration(unixTimeFrom: string, unixTimeTo: string): String{
    const unixTimFromNum = new Date(parseInt(unixTimeFrom) * 1000).getTime()
    const unixTimeToNum = new Date(parseInt(unixTimeTo) * 1000).getTime()
    const diff = unixTimeToNum - unixTimFromNum
    if(diff > 3600000){
        //1h以上なら x時間y分
        const hour = Math.floor(diff / 3600000)
        const minute = Math.floor((diff - (hour * 3600000)) / 60000)
        return hour+"時間"+minute+"分"
    }
    //1h以下は x分y秒
    const minute = Math.floor(diff / 60000)
    const second = Math.floor((diff - (minute * 60000)) / 1000)
    return minute+'分'+second+"秒"
}

export function getJpEventTitle(eventRes: EventResType){
    switch(eventRes.event_name){
        case 'JoinWorldEvent':
            return `${eventRes.world}のインスタンスを訪れた！`
        case 'LeaveWorldEvent':
            return `インスタンスを離れた`
        case 'ComePlayerEvent':
            return `${eventRes.players![0]}がやってきた！`
        case 'LeftPlayerEvent':
            return `${eventRes.players![0]}が移動した`
        case 'ChangeAvatarEvent':
            return 'アバターを変更した'
        case 'LoadVideoEvent':
            return '動画が再生された'
        case 'TakePictureEvent':
            return '写真撮影した！'
        case 'AcceptFriendRequestEvent':
            return `${eventRes.players![0]}とフレンドになった!`
        case 'AcceptInviteEvent':
            return `${eventRes.players![0]}のインバイトに応じた！`
        case 'AcceptRequestInviteEvent':
            return `${eventRes.players![0]}のリクインに応じた！`
        default: 
            return eventRes.event_name
    }
}