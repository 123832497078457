import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Container } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import AwsConfigAuth  from './aws-config/auth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeOptions } from './components/ThemeOption';
import { CssBaseline } from '@mui/material';

import { Route, Routes , BrowserRouter} from 'react-router-dom';
import ResponsiveAppBar from './components/Header';
import Info from './pages/Info';
import Timeline from './pages/Timeline';
import TimeLineDetail from './pages/EventDetail';
import Error404 from './pages/error/404';

import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';

Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  Auth:AwsConfigAuth 
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1800000,
      staleTime: Infinity
    }
  }
});

const theme = createTheme(themeOptions);

root.render(
  <React.StrictMode>
      <Authenticator hideSignUp={true}>
        {({ signOut, user }) => (
          <Authenticator.Provider>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                  <ResponsiveAppBar/>
                  <Container maxWidth="xl">
                    <Routes>
                      <Route path='/' element={<Info/>} />
                      <Route path='/timeline' element={<Timeline/>} />
                      <Route path='/timeline/detail' element={<TimeLineDetail/>} />
                      <Route path='*' element={<Error404/>} />
                    </Routes>
                  </Container>
                </BrowserRouter>
              </ThemeProvider> 
            </QueryClientProvider>
          </Authenticator.Provider>
        )}
      </Authenticator>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
