import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Auth } from "aws-amplify";
import { InfoResType } from "../lib/InfoType";

import {toDisplayDate} from "../lib/lib";
import Card from '@mui/material/Card';
import { CardContent, Typography , CircularProgress} from "@mui/material";

const fetchInfo = async () => {
  const idToken = await Auth.currentSession().then(res =>{
    return res.getIdToken().getJwtToken();
  })
  const res = await axios.post('https://api.vrclc.hennabatch.net/info', null, {
    headers:{
      "Authorization" : idToken
    }
  });
  return res.data;
}

export default function Info() {
  const {data: info, isLoading} = useQuery<InfoResType, Error>(['info'], fetchInfo)

  if(isLoading){
    return(
      <main>
        <h1>info</h1>
        <CircularProgress />
      </main>
    )
  }

  return (
      <main>
        <h1>info</h1>
        <Card>
          <CardContent>
            <Typography variant="body1">
              集計期間: {info && toDisplayDate(info.date_from)} ～ {info && toDisplayDate(info.date_to)}<br />
              出会ったプレイヤー: {info && info.unique_player_count}人<br />
              巡ったワールド: {info && info.unique_world_count}ヶ所<br />
              撮った写真: {info && info.picture_count}枚<br />
              最新の写真<br />
              {info && <img loading="lazy" width="50%" height="50%" src={info.latest_picture_url}></img>}
            </Typography>
          </CardContent>
        </Card>
      </main>
  );
}