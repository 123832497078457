import { EventResType } from "../../lib/EventType"

import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PlaceIcon from '@mui/icons-material/Place';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import HandshakeIcon from '@mui/icons-material/Handshake';
import EmailIcon from '@mui/icons-material/Email';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import CheckroomIcon from '@mui/icons-material/Checkroom';


export type EventIconType ={
    eventInfo: EventResType | undefined
}

export const EventIcon = ({eventInfo}: EventIconType) =>{
    switch(eventInfo ? eventInfo.event_name : ""){
        case 'JoinWorldEvent':
            return <FlightLandIcon/>
        case 'LeaveWorldEvent':
            return <FlightTakeoffIcon/>
        case 'ComePlayerEvent':
            return <GroupAddIcon/>
        case 'LeftPlayerEvent':
            return <GroupRemoveIcon/>
        case 'ChangeAvatarEvent':
            return <CheckroomIcon/>
        case 'LoadVideoEvent':
            return <PlayArrowIcon/>
        case 'TakePictureEvent':
            return <CameraAltIcon/>
        case 'AcceptFriendRequestEvent':
            return <HandshakeIcon/>
        case 'AcceptInviteEvent':
            return <EmailIcon/>
        case 'AcceptRequestInviteEvent':
            return <MailOutlineIcon/>
        default: 
            return <PlaceIcon />
    }
}