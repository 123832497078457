import { useEffect, useState } from "react";
import { useQueries, useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import { Auth } from "aws-amplify";
import { LaunchingListResType } from "../lib/LaunchingListType";
import { LaunchingResType } from "../lib/LaunchingType";
import { calcDuration, toDisplayDate } from "../lib/lib";
import Pagination from '@mui/material/Pagination';
import { JoiningResType } from "../lib/JoiningType";
import { Grid } from "@aws-amplify/ui-react";
import Card from '@mui/material/Card';
import { CardContent, Typography , CircularProgress} from "@mui/material";
import { toDisplayBeforeDays } from "../lib/lib";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import PlaceIcon from '@mui/icons-material/Place';
import 'react-vertical-timeline-component/style.min.css';
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

const fetchList = async () => {
  const idToken = await Auth.currentSession().then(res =>{
    return res.getIdToken().getJwtToken();
  })
  const res = await axios.post('https://api.vrclc.hennabatch.net/launch_list', null, {
    headers:{
      "Authorization" : idToken
    }
  });
  return res.data as LaunchingListResType;
}

const fetchLaunchingInfo = async (launching_uuid: string) => {
  const idToken = await Auth.currentSession().then(res =>{
    return res.getIdToken().getJwtToken();
  })
  const res = await axios.post('https://api.vrclc.hennabatch.net/launching_uuid', {
    'launching_uuid': launching_uuid
  }, {
    headers:{
      "Authorization" : idToken
    }
  });
  return res.data as LaunchingResType;
}

const fetchJoiningInfo = async (joining_uuid: string) => {
  const idToken = await Auth.currentSession().then(res =>{
    return res.getIdToken().getJwtToken();
  })
  const res = await axios.post('https://api.vrclc.hennabatch.net/joining_uuid', {
    'joining_uuid': joining_uuid
  }, {
    headers:{
      "Authorization" : idToken
    }
  });

  return res.data as JoiningResType;
}

type LaunchingInfoType = {
  launchingInfo: LaunchingResType | undefined
}

const LaunchingInfo = ({launchingInfo}: LaunchingInfoType) => {
  if(launchingInfo === undefined){
    return(
      <Card >
        <CircularProgress/>
      </Card>
    )
  }

  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {toDisplayDate(launchingInfo!.date_from)} ～ {toDisplayDate(launchingInfo!.date_to)}<br />
        {calcDuration(launchingInfo!.date_from, launchingInfo!.date_to)}
        </Typography>
        <Typography variant="h5" component="div" sx={{ mb: 1 }}>
          {toDisplayBeforeDays(launchingInfo!.date_from)}のぶいちゃ！
        </Typography>
        <Typography variant="body2">
          出会ったプレイヤー: {launchingInfo!.unique_player_count}人<br />
          巡ったワールド: {launchingInfo!.unique_world_count}ヶ所<br />
          撮った写真: {launchingInfo!.picture_count}枚<br />
          launchingUUID:{launchingInfo.launching_uuid}
        </Typography>
      </CardContent>
    </Card>
  )
}

type JoiningInfoType = {
  joiningInfo: JoiningResType | undefined
}

const JoiningInfo = ({joiningInfo}: JoiningInfoType) => {
  const navigate = useNavigate()

  if(joiningInfo === undefined){
    return (
      <VerticalTimelineElement
      className="vertical-timeline-element-joining"
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      icon={<PlaceIcon />}
    >
      <CircularProgress/>
    </VerticalTimelineElement>
    )
  }

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element-joining"
      date={`${toDisplayDate(joiningInfo!.date_from)} ~ ${toDisplayDate(joiningInfo!.date_to)} / ${calcDuration(joiningInfo!.date_from, joiningInfo!.date_to)}`}
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      icon={<PlaceIcon />}
      onTimelineElementClick={() => navigate(`detail?joiningUUID=${joiningInfo.joining_uuid}`)}
    >
      <h3 className="vertical-timeline-element-title">{joiningInfo.world}</h3>
      <Typography variant="body2">
          出会ったプレイヤー: {joiningInfo.unique_player_count}人<br />
          撮った写真: {joiningInfo.picture_count}枚<br />
      </Typography>
      <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
          joiningUUID:{joiningInfo.joining_uuid}
      </Typography>
    </VerticalTimelineElement>
  )
}

type launchingUUIDType = {
  launchingUUID: string | undefined;
}

export default function Timeline() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pages = Number(searchParams.get("pages"));
  const location = useLocation();
  const launchingUUIDRef = location.state as launchingUUIDType | null
  const {data: launchingList} = useQuery<LaunchingListResType>(['launchingList'], fetchList)
  const launching_uuids = launchingList ? launchingList.launching_uuids : new Array<string>();
  const [currentPage, setcurrentPage] = useState<number>(  0 < pages ? pages : 1)
  useEffect(() =>{
    if(launchingUUIDRef != undefined){
      const len = launchingList ? launchingList.launching_uuids.length : 0;
      for(let i = 0; i < len; i++){
        if(launchingUUIDRef.launchingUUID === launchingList?.launching_uuids[i]){
          setcurrentPage(i + 1)
        }
      }
    }
  }, [launchingList])
  const {data: launchingInfo} = useQuery<LaunchingResType>(['launching', launching_uuids[currentPage - 1]],() => fetchLaunchingInfo(launching_uuids[currentPage - 1]), {enabled: !!launching_uuids.length})
  const joiningInfo = useQueries<Array<JoiningResType>>({
    queries: (launchingInfo ? launchingInfo.joining_uuids : Array<string>()).map(joiningUUID =>{
      return {
        queryKey: ['joining', joiningUUID],
        queryFn: () => fetchJoiningInfo(joiningUUID),
        enabled: launchingInfo !== undefined
      } 
    })
  }) as UseQueryResult<JoiningResType, unknown>[]

  return (
      <main>
        <h1>タイムライン</h1>
        <LaunchingInfo launchingInfo={launchingInfo}/>
        <VerticalTimeline
          layout="1-column-left"
        >
          {
            joiningInfo.sort((a, b) => {
              const aDateFrom = a.data ? parseInt(a.data.date_from) : 0;
              const bDateFrom = b.data ? parseInt(b.data.date_from) : 0;
              return aDateFrom- bDateFrom;
            })
            .map((result) =>{
              const joinRes = result.data;
              return (<JoiningInfo joiningInfo={joinRes}/>)
            })
          }
        </VerticalTimeline>
        <Grid alignItems='center' justifyContent='center'>
          <Pagination 
            count={launchingList ? launchingList.launching_uuids.length: 1}
            defaultPage={1}
            onChange = {
              (e, currentPage) => {
                let q = {pages: currentPage.toString()}
                setSearchParams(q)
                setcurrentPage(currentPage)
              }
            }
            page={currentPage}
          />
        </Grid>
      </main>
  );
}